import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Sites from '../Models/Sites';
import MagazynPage from '../Sites/MagazynPage';
import SuppliersPage from '../Sites/SuppliersPage';
import TowaryForDostawcaPage from '../Sites/TowaryForDostawcaPage';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Popover from '@mui/material/Popover';
import Grid from '@mui/material/Grid';
import UsersPage from '../Sites/UsersPage';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { AppContext, AppContextType } from '../App';
import { UserRole } from "../Models/Jwt";
import BadgeIcon from '@mui/icons-material/Badge';
import ProducentCodePage from '../Sites/ProducentCodePage';
import NumbersIcon from '@mui/icons-material/Numbers';
import DetailsOfTowarForOdbiorcaPage from '../Sites/DetailsOfTowarForOdbiorcaPage';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import SendIcon from '@mui/icons-material/Send';
import WydaniePage from '../Sites/WydaniePage';
import RozwagiPage from '../Sites/RozwagiPage';
import ScaleIcon from '@mui/icons-material/Scale';
import SyncIcon from '@mui/icons-material/Sync';
import Avatar from '@mui/material/Avatar';
import RozchodyWewnetrznePage from '../Sites/RozchodyWewnetrznePage';
import PrzychodyWewnetrznePagePage from '../Sites/PrzychodyWewnetrznePage';
import { useContext } from 'react';
import ZwrotyZewnetrznePage from '../Sites/ZwrotyZewnetrznePage';
import UTurnRightIcon from '@mui/icons-material/UTurnRight';
import { delete_cookie } from 'sfcookies';

const drawerWidth = 240;

let pages: Sites[] = [
  { setting: false, label: "Magazyn", path:"/", icon: <WarehouseIcon />, element: <MagazynPage />, visibleFor: [UserRole.admin, UserRole.magazynier, UserRole.dostawca]},
  { setting: false, label: "Dostawy", path:"/dostawy", icon: <LocalShippingIcon />, element: <SuppliersPage />, visibleFor: [UserRole.admin, UserRole.magazynier, UserRole.dostawca]},
  { setting: false, label: "Rozwagi", path:"/rozwagi", icon: <ScaleIcon />, element: <RozwagiPage />, visibleFor: [UserRole.admin, UserRole.magazynier]},
  { setting: false, label: "Rozchody Wewnętrzne", path:"/rozchody",
    icon: <Avatar sx={{ bgcolor: 'transparent', color: 'gray', fontSize: '16px', fontWeight: '600', width: 'unset', height: 'unset', marginRight: '-2px', marginLeft: '-1px' }} variant="square">RW</Avatar>,
    element: <RozchodyWewnetrznePage />, visibleFor: [UserRole.admin, UserRole.magazynier]},
  { setting: false, label: "Przychody/Zmiany Wewnętrzne", path:"/przychody", icon: <SyncIcon />, element: <PrzychodyWewnetrznePagePage />, visibleFor: [UserRole.admin, UserRole.magazynier]},
  { setting: false, label: "Wydania", path:"/wydania", icon: <SendIcon />, element: <WydaniePage />, visibleFor: [UserRole.admin, UserRole.magazynier]},
  { setting: false, label: "Zwrot zewnętrzny", path:"/zwroty", icon: <UTurnRightIcon />, element: <ZwrotyZewnetrznePage />, visibleFor: [UserRole.admin, UserRole.magazynier]},
  
  { setting: true, label: "Kody Producentoów", path:"/producentCode", icon: <NumbersIcon />, element: <ProducentCodePage />, visibleFor: [UserRole.admin, UserRole.magazynier]},
  { setting: true, label: "Użytkownicy", path:"/users",  icon: <GroupIcon />, element: <UsersPage />, visibleFor: [UserRole.admin]},
  { setting: true, label: "Towary dla Dostawcy", path:"/towaryDlaDostawcy", icon: <BadgeIcon />, element: <TowaryForDostawcaPage />, visibleFor: [UserRole.admin]},
  { setting: true, label: "Detale towarów dla odbiorcy", path:"/detaleTowaruDlaOdbiorcy", icon: <AirlineStopsIcon />, element: <DetailsOfTowarForOdbiorcaPage />, visibleFor: [UserRole.admin]}
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1
  })
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function RenderMenuItems(setting: boolean, open: boolean, appContext: AppContextType | null) {
  const location = useLocation();
  return (
    <List>
      {pages.map((page, index) => ( page.setting === setting && appContext?.userData?.userRole && page.visibleFor.includes(appContext?.userData?.userRole) &&
        <ListItem key={"menuItem"+page.path} disablePadding sx={{ display: 'block' }} selected={page.path === location.pathname}>
          <ListItemButton
            key={"menuLink"+page.path}
            component={Link}
            to={page.path}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              {page.icon}
            </ListItemIcon>
            <ListItemText primary={page.label} sx={{ opacity: open ? 1 : 0, whiteSpace: open ? 'normal' : 'nowrap' }} className='drawer-item'/>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

// interface AppBarAndLeftMenuProps {
//   //setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
// }
export default function AppBarAndLeftMenu() {//{userData} : AppBarAndLeftMenuProps
  const appContext = useContext(AppContext);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpenAndClose = async () => {
    await setOpen(!open);
  };

  const [popperAnchorEl, setPopperAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const popperHandleOpen = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await setPopperAnchorEl(event.currentTarget);
  };

  const popperHandleClose = async () => {
    await setPopperAnchorEl(null);
  };

  const popperOpen = Boolean(popperAnchorEl);
  const popperId = popperOpen ? 'simple-popover' : undefined;

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open/close drawer" onClick={handleDrawerOpenAndClose} edge="start" sx={{ marginRight: '13px' }}>
            {(open) ? (<ChevronLeftIcon />) : (<MenuIcon />)}
          </IconButton>
          <Typography variant="h1" noWrap component="div" sx={{fontSize: 26, fontWeight: 600}}>
            SPGJ
          </Typography>
          <div className='userData'>
            <div style={{paddingRight: '5px'}}>
              { appContext?.userData?.userName}
            </div>
            <IconButton aria-describedby={popperId} onClick={popperHandleOpen}>
              <AccountCircleIcon style={{color: 'white', width: '40px', height: '40px'}} />
            </IconButton>
            <Popover
              id={popperId}
              open={popperOpen}
              anchorEl={popperAnchorEl}
              onClose={popperHandleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <div style={{ margin: '16px 0px 8px 14px', maxWidth: '210px' }}>
                <Grid container rowSpacing={1} alignItems={'center'} columnSpacing={{ xs: 1}}>
                  <Grid item xs={6}>
                    Rola:
                  </Grid>
                  <Grid item xs={6}>
                    { appContext?.userData?.userRole}
                  </Grid>
                  <Grid item xs={6}>
                    Wyloguj:
                  </Grid>
                  <Grid item xs={6}>
                    <IconButton onClick={async () => {
                        //localStorage.removeItem("JWT");
                        delete_cookie("JWT");
                        appContext?.updateIsLoggedIn(false);
                      }}>
                      <LogoutIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </div>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} className="drawer">
        <DrawerHeader />
        <div className="drawer-items">
          {RenderMenuItems(false, open, appContext)}
          <Divider />
          {RenderMenuItems(true, open, appContext)}
        </div>
      </Drawer>
      <Box sx={{width: {xs: `calc(100% - ${open ? "240px" : "57px"})`, sm: `calc(100% - ${open ? "240px" : "65px"})`}}} style={{transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"}}>
        <DrawerHeader />
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: 'auto', height: 'calc(100% - 64px)', padding: '0 20px 50px 20px' }} >
          <Routes>
            {pages.map((page) => (
              <Route path={page.path} element={page.element} key={"route"+page.path} />
            ))}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
