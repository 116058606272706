import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import AddIcon from '@mui/icons-material/Add';
import EditableGrid from '../Features/EditableGrid';
import { Autocomplete, Button, Checkbox, darken, Dialog, DialogActions, DialogContent, DialogTitle, Grid, lighten, styled, TextField } from '@mui/material';
import ModalElement from '../Features/Modal';
import { towaryColumns } from '../Models/Supply';
import { getDostawcyApi, assignTowaryToDostawca, getAllTowaryApi, getTowaryForDostawca2, removeTowaryFromDostawcaApi, editTowarForDostawca } from '../Services/SupplyService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { enqueueSnackbar } from 'notistack';
import { Debounce } from '../Helpers/ButtonDebouncing';

function TowaryForDostawcaPage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [choosenDostawca, setChoosenDostawca] = useState<number | null>(null);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(false);
  const [loadingCreateButton, setLoadingCreateButton] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteSelectedButton = () => {
    setDeleting(true);
  };
  const handleDeleteNo = () => {
    setDeleting(false);
  };
  const handleDeleteYes = async () => {
    await deleteTowaryFromDostawcaAction(rowSelectionModel.map(s => (s)));
    setRowSelectionModel([]);
    setDeleting(false);
  };
  const renderConfirmDeleteDialog = () => {
    if (!deleting) {
      return null;
    }
    return (
      <Dialog maxWidth="xs" open={!!deleting}>
        <DialogTitle>Czy na pewno chcesz odpisać towary ({rowSelectionModel.length}) od dostawcy?</DialogTitle>
        <DialogContent dividers>
          {`Potwierdzenie 'Tak' odpisze towary.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteNo}>Nie</Button>
          <Button onClick={handleDeleteYes}>Tak</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const rowSelectionModelChange = (param: any) => {
    setRowSelectionModel(param);
  };
  
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {setOpenModal(true);};
  const handleCloseModal = () => {setOpenModal(false);}
  
  const [optionsTowary, setOptionsTowary] = useState<any[]>([]);
  const [filteredOptionsTowary, setFilteredOptionsTowary] = useState<any[]>([]);
  const getAllTowaryEcxept = useCallback(async (except: any[]) => {
    if (optionsTowary.length === 0) {
      await getAllTowaryApi().then(
        async (response: AxiosResponse) => {
          setOptionsTowary(response.data);
          setFilteredOptionsTowaryFunction(except, response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    } else {
      setFilteredOptionsTowaryFunction(except, optionsTowary);
    }
  }, [optionsTowary]);
  const setFilteredOptionsTowaryFunction = (except: any[], towary: any[]) => {
    setFilteredOptionsTowary(towary.filter(x => except.find(y => x.key === y.idObject) === undefined));
  };

  const initialize = useCallback(async () => {
    if (choosenDostawca) {
      setLoadingInitialization(true);
      await getTowaryForDostawca2(choosenDostawca).then(
        (response: AxiosResponse) => {
          setRows(response.data);
          setLoadingInitialization(false);
          getAllTowaryEcxept(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingInitialization(false);
        });
    } else {
      setRows([]);
    }
  }, [choosenDostawca, getAllTowaryEcxept]);
  useEffect(() => { initialize(); }, [initialize]);

  const [dostawcyOptions, setDostawcyOptions] = useState([]);
  const getDostawcy = useCallback(async ()=> {
    await getDostawcyApi().then(
      (response: AxiosResponse) => {
        setDostawcyOptions(response.data)
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      }
    );
  }, []);
  useEffect(() => { getDostawcy(); }, [getDostawcy]);
  
  const deleteSingleTowarFromDostawcaAction = async (row: any) => {
    var towaryToDeleteList = [row.id];
    await deleteTowaryFromDostawcaAction(towaryToDeleteList);
  };
  
  const deleteTowaryFromDostawcaAction = async (rows: GridRowId[]) => {
    setLoadingInitialization(true);
    await removeTowaryFromDostawcaApi(rows).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        setRowSelectionModel([]);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

  const addAction = async () => {
    if (myState.current.length === 0) {
      enqueueSnackbar('Proszę zaznaczyć towar.', { variant: 'info' });
    } else {
      setLoadingCreateButton(true);
      await assignTowaryToDostawca((myState.current as any[]).map(s => (s.key)), choosenDostawca!).then(
        async (response: AxiosResponse) => {
          await Debounce(setLoadingCreateButton, false);
          myState.current = [];
          initialize();
          handleCloseModal();
          handleSuccess(response);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingCreateButton(false);
        }
      )
    }
  };
  
  const editAction = async (newRow: any, oldRow: any) : Promise<boolean> => {
    return await editTowarForDostawca(newRow.id, newRow.price)
      .then(
        (response: AxiosResponse) => {
          initialize();
          handleSuccess(response);
          return true;
        })
      .catch(
        (error: AxiosError) => {
          handleErros(error, appContext);
          return false;
        }
      );
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    zIndex: 1,
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  const GroupItems = styled('ul')({
    padding: 0,
  });
  let myState = useRef<any>([]); // prevent state from update - thanks to that autocomplete is not re-rendering each time after choosing option
  const modalContentAddTowary = () => {
    return (
      <>
        <h1 style={{ margin: '0 0 20px 0' }}>Wybierz towary dla dostawcy</h1>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {(openModal) && <Autocomplete  // rerender modal each time because in autocomplete there is no value defined so choosen elements stay as picked but we are removing them to not choose second time
              multiple
              defaultValue={[]}
              options={filteredOptionsTowary}
              disableCloseOnSelect
              onChange={(event: any, newValue: any, reason, details) => {
                myState.current = newValue;
              }}
              groupBy={(option) => option.nazwaGrupy}
              classes={{ paper: "optionsForTowaryForDostawca" }}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader onClick={() => {
                    (document.getElementById("groupItems" + params.key)!).style.display === "none" ?
                    (document.getElementById("groupItems" + params.key)!).style.display = "block" :
                    (document.getElementById("groupItems" + params.key)!).style.display = "none";
                    }}>Grupa {params.group} ({(params.children as any).length})</GroupHeader>
                  <GroupItems id={"groupItems" + params.key} style={{display: "none"}}>{params.children}</GroupItems>
                </li>
              )}
              renderOption={(props, option: any, { selected }) => (
                <li {...props} key={option.id} style={{paddingBottom: 0, paddingTop: 0}}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => ( <TextField {...params} label="Wybierz towary"  /> )}
            />}
          </Grid>
          <Grid item xs={12}>
            <LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
              endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
              loading={loadingCreateButton}
              loadingPosition="center"
              variant="contained"
              onClick={addAction} >
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    (appContext?.userData?.userRole === UserRole.admin) ?
    <><h1>Towary dla dostawcy</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "28px !important", textAlign: 'center'}}>
      {rowSelectionModel.length > 0 &&
        <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'}>
          <LoadingButton sx={{ lineHeight: '33px' }}
            endIcon={<DeleteSweepIcon style={{fontSize: '30px'}}/>}
            loadingPosition="end"
            variant="outlined"
            onClick={handleDeleteSelectedButton}>Usuń zaznaczone
          </LoadingButton>
        </Grid>}
      <Grid item xs={12} sm={8} md={4} lg={3}>
        <Autocomplete
          options={dostawcyOptions}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          onChange={(e, data: any) => {
            if (data != null) { setChoosenDostawca(data.id) } else { setChoosenDostawca(null); setRowSelectionModel([]); }
          }}
          defaultValue={null}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              label="Wybierz dostawce"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <LoadingButton className='customAddLoadingButton'
          endIcon={<AddIcon sx={{m: '0px', fontSize: "30px !important"}}/>}
          loadingPosition="center"
          disabled={choosenDostawca === null}
          onClick={handleOpenModal}>
        </LoadingButton>
      </Grid>
    </Grid>
    {renderConfirmDeleteDialog()}
    <EditableGrid
      columns={towaryColumns}
      rows={rows}
      editAction={editAction}
      loading={loadingInitialization}
      setPaginationModel={() => null}
      paginationModel={{pageSize: 100, page: 0}}
      total={0}
      setQueryOptions={() => null}
      doubleClickRowAction={() => null}
      deleteAction={deleteSingleTowarFromDostawcaAction}
      hideDeleteAction={false}
      hideEditAction={false}
      hideActionColumn={false}
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      setRowsSelectionModel={rowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      hideFooter={true}
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={undefined}
      additionalStyles={undefined}
    />
    <ModalElement handleClose={handleCloseModal} open={openModal} modalContent={modalContentAddTowary()} centerCloseButton={false}
      additionalStyles={{width: '60% !important', minWidth: '500px !important', top: '10% !important'}}/>
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default TowaryForDostawcaPage;
