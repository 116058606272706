import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { UserRole } from '../../Models/Jwt';
import { Autocomplete, Box, Checkbox, darken, Grid, InputAdornment, lighten, styled, TextField } from '@mui/material';
import { vat_options } from '../../Models/Supply';
import { createDostawaDetails } from '../../Services/SupplyService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros } from '../../Helpers/AxiosCustomHeaders';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Controller, useForm } from 'react-hook-form';
import { allowToDuplicateTowaryInDostawa } from '../../Models/Magazyn';

interface AddDetailsModalProps {
  currentDostawaId: any;
	initializeDetails: () => void;
	closeAddDetails: () => void;
	loadingButtons: boolean;
  setLoadingButtons: Dispatch<SetStateAction<boolean>>;
  setOptionsTowary: Dispatch<SetStateAction<any>>;
	optionsTowary: any;
  setFilteredOptionsTowary: Dispatch<SetStateAction<any>>;
	filteredOptionsTowary: any;
	forceReset: boolean;
  vatSetOnUser: boolean;
}
function AddDetailsModal({
	currentDostawaId,
	initializeDetails,
	closeAddDetails,
	loadingButtons,
	setLoadingButtons,
	setOptionsTowary,
	optionsTowary,
	setFilteredOptionsTowary,
	filteredOptionsTowary,
	forceReset,
  vatSetOnUser
} : AddDetailsModalProps) {
	const appContext = useContext(AppContext);
	const [labelForNazwaTowaru, setLabelForNazwaTowaru] = useState("");
	const [value, setValue] = useState<any>(null);
	const [valuePrice, setValuePrice] = useState<string>("");
	const [checkBoxChecked, setCheckBoxChecked] = useState(false);

	const { control, handleSubmit, reset, register } = useForm({ mode: 'all' });
  useEffect(() => { reset(); setValue(null); setValuePrice(""); setLabelForNazwaTowaru(""); }, [forceReset, reset]);

  const handleInitializeDetails = useCallback(() => {
    return initializeDetails()
  }, [initializeDetails])
  const handleCloseAddDetails = useCallback(() => {
    return closeAddDetails()
  }, [closeAddDetails])
  const handleSetLoadingButtons = useCallback((event: boolean) => {
    setLoadingButtons(event)
  }, [setLoadingButtons])
  const handleSetOptionsTowary = useCallback((event: string | null) => {
    setOptionsTowary(event)
  }, [setOptionsTowary])
  const handleSetFilteredOptionsTowary = useCallback((event: string | null) => {
    setFilteredOptionsTowary(event)
  }, [setFilteredOptionsTowary])

	const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(null);
		setLabelForNazwaTowaru("");
		setCheckBoxChecked(event.target.checked);
	};

	const addAction = async (data: any) => {
		handleSetLoadingButtons(true);
		await createDostawaDetails(
			checkBoxChecked ? data.towar.key : data.towar.id,
			Number(data.quantity),
			currentDostawaId,
			Number(data?.vat?.id) || null,
			data?.vat?.label === "0.00" ? 0 : Number(data?.vat?.label) || null,
			Number(data?.price) || null,
			data.additionalInfo || null
		).then(
			(response: AxiosResponse) => {
				handleSetLoadingButtons(false);
				handleInitializeDetails();
				reset();
				setValue(null);
        if (!allowToDuplicateTowaryInDostawa) {
          handleSetOptionsTowary(checkBoxChecked ?
            optionsTowary.filter((x: { id: any; }) => x.id !== data.towar.key) :
            optionsTowary.filter((x: { id: any; }) => x.id !== data.towar.id));
          handleSetFilteredOptionsTowary(checkBoxChecked ?
            filteredOptionsTowary.filter((x: { key: any; }) => x.key !== data.towar.key) :
            filteredOptionsTowary.filter((x: { key: any; }) => x.key !== data.towar.id));
        }
				setLabelForNazwaTowaru("");
				handleCloseAddDetails();
			},
			(error: AxiosError) => {
				handleErros(error, appContext);
				handleSetLoadingButtons(false);
			}
		)
	};

	return (
		<Box component="form" onSubmit={handleSubmit(addAction)} textAlign="center">
			<h1 style={{ margin: '0 0 20px 0' }}>Dodaj detale dostawy</h1>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Controller
						control={control}
						name="towar"
						defaultValue=""
						rules={{ required: true }}
						render={({ field: { onChange }, fieldState: { error } }) => (
							<Autocomplete
								value={value}
								options={checkBoxChecked ? filteredOptionsTowary : optionsTowary}
								classes={{ paper: "optionsForTowarNameForDostawaDetail" }}
								isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
								renderOption={(props, option: any) => (
									<li {...props} key={option.id}>
										{option.label}
									</li>
								)}
								groupBy={(option) => { if (checkBoxChecked) { return option.nazwaGrupy } else { return null } }}
								renderGroup={(params) => (
									<li key={params.key}>
										{checkBoxChecked &&
											<GroupHeader onClick={() => {
												(document.getElementById("groupItems" + params.key)!).style.display === "none" ?
													(document.getElementById("groupItems" + params.key)!).style.display = "block" :
													(document.getElementById("groupItems" + params.key)!).style.display = "none";
											}}>Grupa {params.group} ({(params.children as any).length})</GroupHeader>}
										<GroupItems id={"groupItems" + params.key} style={{ display: `${checkBoxChecked ? "none" : "block"}` }}>{params.children}</GroupItems>
									</li>
								)}
								onChange={(e, data) => {
									onChange(data);
									setValue(data);
									if (data !== null) {
										checkBoxChecked ? setLabelForNazwaTowaru(` ID = ${data.key}`) : setLabelForNazwaTowaru(` ID = ${data.id}`);
									} else {
										setLabelForNazwaTowaru("");
									}
								}}
								renderInput={(params) =>
									<TextField
										{...params}
										fullWidth
										variant="filled"
										label={`Nazwa towaru${labelForNazwaTowaru}`}
										error={error !== undefined}
										autoComplete="cc-number"
										InputProps={{
											...params.InputProps,
											startAdornment: ((appContext?.userData?.userRole !== UserRole.dostawca) &&
												<InputAdornment className='switchInAutocomplete' position="start">
													<Checkbox icon={<WarehouseIcon color="disabled" />} checkedIcon={<WarehouseIcon color="primary" />} checked={checkBoxChecked} onChange={handleCheckBoxChange} />
												</InputAdornment>
											)
										}}
									/>
								}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Controller
						control={control}
						name="quantity"
						defaultValue=""
						rules={{ required: true }}
						render={({ fieldState: { error } }) => (
							<TextField
								{...register("quantity", {
									validate: (value) =>
										(value >= 1) || "Pełna wartość i wieksza od 0"
								})}
								fullWidth
								type="number"
								variant="filled"
								label={error?.message ? error?.message : "Ilość"}
								error={error !== undefined}
								autoComplete="cc-number"
							/>
						)}
					/>
				</Grid>
				{(appContext?.userData?.userRole !== UserRole.dostawca && vatSetOnUser === false) && <Grid item xs={12}>
					<Controller
						control={control}
						name="vat"
						defaultValue=""
						rules={{ required: false }}
						render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
							<Autocomplete
								options={vat_options}
								onChange={(_, data) => onChange(data)}
								isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
								renderOption={(props, option: any) => (
									<li {...props} key={option.id}>
										{option.label}
									</li>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										{...field}
										fullWidth
										inputRef={ref}
										variant="filled"
										label="Vat"
										error={error !== undefined}
									/>
								)}
							/>
						)}
					/>
				</Grid>}
				{/* {(appContext?.userData?.userRole !== UserRole.dostawca) && <Grid item xs={12}>
					<Controller
						control={control}
						name="price"
						defaultValue=""
						rules={{ required: false }}
						render={({ fieldState: { error } }) => (
							<TextField
								{...register("price", {
									onChange: (value) => {setValuePrice(value.target.value.replace(',', '.'))},
									validate: (value) => (value >= 0.01) || value === "" || "Wartość wieksza od 0.01"
								})}
								fullWidth
								value={valuePrice}
								type="float"
								variant="filled"
								label={error?.message ? error?.message : "Cena"}
								error={error !== undefined}
								autoComplete="cc-number"
							/>
						)}
					/>
				</Grid>} */}
				<Grid item xs={12}>
					<Controller
						control={control}
						name="additionalInfo"
						defaultValue=""
						rules={{ required: false }}
						render={({ fieldState: { error } }) => (
							<TextField
								{...register("additionalInfo", {
									validate: (value) =>
										(value.length < 100) || "Treść krótsza niż 100 znaków"
								})}
								fullWidth
								variant="filled"
								label={error?.message ? error?.message : "Uwagi"}
								error={error !== undefined}
								autoComplete="cc-number"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12}>
					<LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
						endIcon={<UploadIcon style={{ fontSize: '30px' }} />}
						loading={loadingButtons}
						loadingPosition="center"
						variant="contained"
						type='submit' >
					</LoadingButton>
				</Grid>
			</Grid>
		</Box>
	)
};

export default AddDetailsModal;

const GroupHeader = styled('div')(({ theme }) => ({

	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	zIndex: 1,
	color: theme.palette.primary.main,
	backgroundColor:
		theme.palette.mode === 'light'
			? lighten(theme.palette.primary.light, 0.85)
			: darken(theme.palette.primary.main, 0.8),
}));
const GroupItems = styled('ul')({
	padding: 0,
});