import { GridColDef } from "@mui/x-data-grid/models";
import { getGridStringOperators } from "@mui/x-data-grid/colDef/gridStringOperators";
import { Vat_enum } from "./Supply";

export enum UserRole {
    admin = "Admin",
    dostawca = "Dostawca",
    magazynier = "Magazynier"
}
export enum UserStatus {
    aktywny = "Aktywny",
    wylaczony = "Wyłączony"
}
export const userColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'Id',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 60,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'userName',
        headerName: 'Nazwa',
        editable: true,
        sortable: false,
        hideable: false,
        width: 200,
        filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains')
    },
    {
        field: 'password',
        headerName: 'Hasło',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        renderCell: () => (
          <strong>
            ***
          </strong>
        ),
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 120,
        valueOptions: ['Aktywny','Wyłączony']
    },
    {
        field: 'userRole',
        headerName: 'Rola',
        type: 'singleSelect',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 120,
        valueOptions: Object.values(UserRole)
    },
    {
        field: 'userNameRaks',
        headerName: 'Nazwa w Raksie',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 100,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'idDostawcaRaks',
        headerName: 'Id dostawcy w Raksie',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 90,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'magazynNumberRaks',
        headerName: 'Numer magazynu w Raksie',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 100,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'idLokalizacjiRaks',
        headerName: 'Id lokalizacji',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 100,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'vat_WartoscStawkiRaks',
        headerName: 'Vat',
        type: 'singleSelect',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 100,
        valueOptions: Object.values(Vat_enum),
        headerAlign: "center",
        align: "center",
        valueGetter: ( value ) => value === null ? "-" : value
    },
    {
        field: 'producentCode',
        headerName: 'Kod Producenta',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: false,
        width: 110,
        headerAlign: "center",
        align: "center"
    },
    {
        field: 'lastTimeLoggedDate',
        headerName: 'Ostatnie logowanie',
        type: 'dateTime',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: true,
        width: 170,
        valueGetter: ( value ) => value && new Date(value),
        align: "left"
    },
    {
        field: 'createdDate',
        headerName: 'Utworzony',
        type: 'dateTime',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: true,
        width: 170,
        valueGetter: ( value ) => value && new Date(value)
    },
    {
        field: 'updatedDate',
        headerName: 'Edytowany',
        type: 'dateTime',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: true,
        width: 170,
        valueGetter: ( value ) => value && new Date(value)
    },
    {
        field: 'updatedBy',
        headerName: 'Edytor',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: true,
        width: 150
    },
    {
        field: 'forceLogin',
        headerName: 'Wymagane ponowne logowanie',
        type: 'boolean',
        editable: false,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        hideable: true,
        width: 100,
        headerAlign: "center",
        align: "center"
    }
];

function getUserRoleByValue(value: string) {
    const indexOfS = Object.values(UserRole).indexOf(value as unknown as UserRole);
    const key = Object.keys(UserRole)[indexOfS];
    return key;
}

export interface Jwt {
    userId: string;
    userName: string;
    userRole: UserRole;
    exp: number
}