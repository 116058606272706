import Typography from '@mui/material/Typography';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../App';
import { UserRole } from '../Models/Jwt';
import AddIcon from '@mui/icons-material/Add';
import EditableGrid from '../Features/EditableGrid';
import { Autocomplete, Button, Checkbox, darken, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, lighten, styled, TextField, Tooltip } from '@mui/material';
import ModalElement from '../Features/Modal';
import { getAllTowaryApi } from '../Services/SupplyService';
import { getAllDetailsOfTowarForOdbiorcaApi, createDetailsOfTowarForOdbiorcaApi, removeDetailsOfTowarForOdbiorcaApi, editDetailsOfTowarForOdbiorcaApi } from '../Services/DetailsOfTowarForOdbiorcaService';
import { AxiosError, AxiosResponse } from 'axios';
import { handleErros, handleSuccess } from '../Helpers/AxiosCustomHeaders';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import { GridColumnGroupingModel, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { enqueueSnackbar } from 'notistack';
import { searchDostawcaByNameApi } from '../Services/UserService';
import { getAllOdbiorcaWithDetailsInDbApi } from '../Services/DetailsOfTowarForOdbiorcaService';
import { detailsOfTowarForOdbiorcaColumns } from '../Models/DetailsOfTowarForOdbiorca';
import StorageIcon from '@mui/icons-material/Storage';
import { Debounce } from '../Helpers/ButtonDebouncing';
import Decimal from 'decimal.js';

function DetailsOfTowarForOdbiorcaPage() {
  const appContext = useContext(AppContext);
  const [rows, setRows] = useState<any[]>([]);
  const [choosenOdbiorca, setChoosenOdbiorca] = useState<number | null>(null);
  const [loadingInitialization, setLoadingInitialization] = useState<boolean>(false);
  const [loadingCreateButton, setLoadingCreateButton] = useState<boolean>(false);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [deleting, setDeleting] = useState(false);
	const [checkBoxChecked, setCheckBoxChecked] = useState(false);
	const [value, setValue] = useState<any>(null);

  const handleDeleteSelectedButton = () => {
    setDeleting(true);
  };
  const handleDeleteNo = () => {
    setDeleting(false);
  };
  const handleDeleteYes = async () => {
    await removeDetailsOfTowarForOdbiorcaAction(rowSelectionModel.map(s => (s)));
    setRowSelectionModel([]);
    setDeleting(false);
  };
  const renderConfirmDeleteDialog = () => {
    if (!deleting) {
      return null;
    }
    return (
      <Dialog maxWidth="xs" open={!!deleting}>
        <DialogTitle>Czy na pewno chcesz odpisać towary ({rowSelectionModel.length}) od dostawcy?</DialogTitle>
        <DialogContent dividers>
          {`Potwierdzenie 'Tak' odpisze towary.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteNo}>Nie</Button>
          <Button onClick={handleDeleteYes}>Tak</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(null);
		setCheckBoxChecked(event.target.checked);
    getExistingDostawcyWithOptions();
	};

  const rowSelectionModelChange = (param: any) => {
    setRowSelectionModel(param);
  };
  
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {setOpenModal(true);};
  const handleCloseModal = () => {setOpenModal(false);}
  
  const [optionsTowary, setOptionsTowary] = useState<any[]>([]);
  const [filteredOptionsTowary, setFilteredOptionsTowary] = useState<any[]>([]);
  const getAllTowaryEcxept = useCallback(async (except: any[]) => {
    if (optionsTowary.length === 0) {
      await getAllTowaryApi(false).then(
        async (response: AxiosResponse) => {
          setOptionsTowary(response.data);
          setFilteredOptionsTowaryFunction(except, response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    } else {
      setFilteredOptionsTowaryFunction(except, optionsTowary);
    }
  }, [optionsTowary]);
  const setFilteredOptionsTowaryFunction = (except: any[], towary: any[]) => {
    setFilteredOptionsTowary(towary.filter(x => except.find(y => x.key === y.towarId) === undefined));
  };

  const initialize = useCallback(async () => {
    if (choosenOdbiorca) {
      setLoadingInitialization(true);
      await getAllDetailsOfTowarForOdbiorcaApi(choosenOdbiorca).then(
        (response: AxiosResponse) => {
          setRows(response.data);
          setLoadingInitialization(false);
          getAllTowaryEcxept(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingInitialization(false);
        });
    } else {
      setRows([]);
    }
  }, [choosenOdbiorca, getAllTowaryEcxept]);
  useEffect(() => { initialize(); }, [initialize]);

  const [optionsForDostawcy, setOptionsForDostawcy] = useState([]);
  const onIdDostawcaRaksInputChange = (event: any, value: string, reason: any) => {
    if (value) {
      searchDostawcaByNameApi(value).then(
        (response: AxiosResponse) => {
          setOptionsForDostawcy(response.data);
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
        });
    } else {
      setOptionsForDostawcy([]);
    }
  };

  const [filteredOptionsDostawcy, setfilteredOptionsDostawcy] = useState([]);
  const getExistingDostawcyWithOptions = () => {
    getAllOdbiorcaWithDetailsInDbApi().then(
      (response: AxiosResponse) => {
        setfilteredOptionsDostawcy(response.data);
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
      });
  };
  
  const removeSingleDetailsOfTowarForOdbiorcaAction = async (row: any) => {
    var towaryToDeleteList = [row.id];
    await removeDetailsOfTowarForOdbiorcaAction(towaryToDeleteList);
  };
  
  const removeDetailsOfTowarForOdbiorcaAction = async (rows: GridRowId[]) => {
    setLoadingInitialization(true);
    await removeDetailsOfTowarForOdbiorcaApi(rows).then(
      (response: AxiosResponse) => {
        handleSuccess(response);
        setLoadingInitialization(false);
        setRowSelectionModel([]);
        initialize();
      },
      (error: AxiosError) => {
        handleErros(error, appContext);
        setLoadingInitialization(false);
      }
    );
  };

  const addAction = async () => {
    if (myState.current === null || myState.current === undefined) {
      enqueueSnackbar('Proszę wybrać towar.', { variant: 'info' });
    } else if (threeKg === null || threeKg === "" || threeKg === undefined ||
    epsOneZeroFour === null || epsOneZeroFour === "" || epsOneZeroFour === undefined ||
    epsOneThreeSix === null || epsOneThreeSix === "" || epsOneThreeSix === undefined ||
    carton === null || carton === "" || carton === undefined ||
    tenKg === null || tenKg === "" || tenKg === undefined
    ) {
      enqueueSnackbar('Proszę uzupełnić wszystkie pola.', { variant: 'info' });
    } else {
      setLoadingCreateButton(true);
      await createDetailsOfTowarForOdbiorcaApi(choosenOdbiorca!, myState.current.key, newTowarName, Number(threeKg), Number(epsOneZeroFour), Number(epsOneThreeSix), Number(carton), Number(tenKg)).then(
        async (response: AxiosResponse) => {
          await Debounce(setLoadingCreateButton, false);
          myState.current = null;
          initialize();
          handleCloseModal();
          handleSuccess(response);

          setNewTowarName("");
          setThreeKg("");
          setEpsOneZeroFour("");
          setEpsOneThreeSix("");
          setCarton("");
          setTenKg("");
        },
        (error: AxiosError) => {
          handleErros(error, appContext);
          setLoadingCreateButton(false);
        }
      )
    }
  };

  const editAction = async (newRow: any, oldRow: any) : Promise<boolean> => {
    return await editDetailsOfTowarForOdbiorcaApi(
      newRow.id,
      newRow.name,
      newRow.threeKg,
      newRow.epsOneZeroFour,
      newRow.epsOneThreeSix,
      newRow.carton,
      newRow.tenKg,
      newRow.price)
      .then(
        (response: AxiosResponse) => {
          initialize();
          handleSuccess(response);
          return true;
        })
      .catch(
        (error: AxiosError) => {
          handleErros(error, appContext);
          return false;
        }
      );
  };

  
  const [newTowarName, setNewTowarName] = useState<string>("");
  const handleNewTowarName = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setNewTowarName(event.target.value);
  }
  const [threeKg, setThreeKg] = useState<string>("");
  const handleThreeKg = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setThreeKg(event.target.value);
  }
  const [epsOneZeroFour, setEpsOneZeroFour] = useState<string>("");
  const handleEpsOneZeroFour = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setEpsOneZeroFour(event.target.value);
  }
  const [epsOneThreeSix, setEpsOneThreeSix] = useState<string>("");
  const handleEpsOneThreeSix = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setEpsOneThreeSix(event.target.value);
  }
  const [carton, setCarton] = useState<string>("");
  const handleCarton = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setCarton(event.target.value);
  }
  const [tenKg, setTenKg] = useState<string>("");
  const handleTenKg = (event: { target: { value: React.SetStateAction<string>; }; })=> {
    setTenKg(event.target.value);
  }

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    zIndex: 1,
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  const GroupItems = styled('ul')({
    padding: 0,
  });
  let myState = useRef<any>(null); // prevent state from update - thanks to that autocomplete is not re-rendering each time after choosing option
  const modalContentAddRelation = () => {
    return (
      <>
        <h1 style={{ margin: '0 0 20px 0' }}>Dodaj detale towaru dla odbiorcy</h1>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{mb: '15px'}}>
            {(openModal) && <Autocomplete  // rerender modal each time because in autocomplete there is no value defined so choosen elements stay as picked but we are removing them to not choose second time
              defaultValue={null}
              options={filteredOptionsTowary}
              onChange={(event: any, newValue: any, reason, details) => {
                myState.current = newValue;
              }}
              groupBy={(option) => option.nazwaGrupy}
              classes={{ paper: "optionsForTowaryForDostawca" }}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader onClick={() => {
                    (document.getElementById("groupItems" + params.key)!).style.display === "none" ?
                    (document.getElementById("groupItems" + params.key)!).style.display = "block" :
                    (document.getElementById("groupItems" + params.key)!).style.display = "none";
                    }}>Grupa {params.group} ({(params.children as any).length})</GroupHeader>
                  <GroupItems id={"groupItems" + params.key} style={{display: "none"}}>{params.children}</GroupItems>
                </li>
              )}
              renderOption={(props, option: any, { selected }) => (
                <li {...props} key={option.id} style={{paddingBottom: 0, paddingTop: 0}}>
                  {option.label}
                </li>
              )}
              renderInput={(params) => ( <TextField {...params} label={`Wybierz towar *`} /> )}
            />}
          </Grid>
          <Grid item xs={12}>
            {choosenOdbiorca !== null && (choosenOdbiorca === 1016 || choosenOdbiorca === 1035 || choosenOdbiorca === 1639 || choosenOdbiorca === 1704 || choosenOdbiorca === 1893) &&
              <span style={{ marginTop: '-5px', textAlign: 'left', width: '100%', display: 'block' }}>
                Dodaj jeszcze <b>"Numer produktu nabywcy"</b> i <b>"Kaliber"</b>!<br></br>Oddziel informacje używając || (dwóch pionowych kresek) bez żadnych dodatkowych spacji. Czyli np:
                <br></br><b>Nowa nazwa towaru||NumerProduktuNabywcy||Kaliber</b>
              </span>}
            <TextField
              onChange={handleNewTowarName}
              variant="outlined"
              margin='dense'
              fullWidth
              id="towarname"
              name="towarname"
              label="Nowa nazwa towaru"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleThreeKg}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="threeKg"
              name="threeKg"
              label="3kg"
              type="number"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleEpsOneZeroFour}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="epsOneZeroFour"
              name="epsOneZeroFour"
              label="ESP104"
              type="number"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleEpsOneThreeSix}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="epsOneThreeSix"
              name="epsOneThreeSix"
              label="ESP136"
              type="number"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleCarton}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="carton"
              name="carton"
              label="Karton"
              type="number"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={handleTenKg}
              variant="outlined"
              margin='dense'
              required
              fullWidth
              id="tenKg"
              name="tenKg"
              label="10kg"
              type="number"
              size="medium"
              autoComplete="cc-number"
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton sx={{ width: 1, height: '56px', mt: 3 }}
              endIcon={<UploadIcon style={{fontSize: '30px'}}/>}
              loading={loadingCreateButton}
              loadingPosition="center"
              variant="contained"
              onClick={addAction} >
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    )
  }

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Ilość sztuk w opakowaniu handlowym',
      children: [{ field: 'threeKg' }, { field: 'epsOneZeroFour' }, { field: 'epsOneThreeSix' }, { field: 'carton' }, { field: 'tenKg' }],
      headerAlign: 'center'
    }
  ]

  return (
    (appContext?.userData?.userRole === UserRole.admin) ?
    <><h1>Detale towarów dla odbiorcy</h1>
    <Grid container rowSpacing={1} alignItems={'center'} justifyContent={'center'} columnSpacing={{ xs: 1}}
      sx={{marginTop: "-10px !important", marginBottom: "28px !important", textAlign: 'center'}}>
      {rowSelectionModel.length > 0 &&
        <Grid item xs={'auto'} sm={'auto'} md={'auto'} lg={'auto'}>
          <LoadingButton sx={{ lineHeight: '33px' }}
            endIcon={<DeleteSweepIcon style={{fontSize: '30px'}}/>}
            loadingPosition="end"
            variant="outlined"
            onClick={handleDeleteSelectedButton}>Usuń zaznaczone
          </LoadingButton>
        </Grid>}
      <Grid item xs={12} sm={8} md={6} lg={5}>
        <Autocomplete
					value={value}
          options={checkBoxChecked ? filteredOptionsDostawcy : optionsForDostawcy}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderOption={(props, option: any) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          classes={{ paper: "optionsForIdDostawcaRaks" }}
          onChange={(e, data: any) => {
            if (data != null) { setValue(data); setChoosenOdbiorca(data.id) } else { setValue(null); setChoosenOdbiorca(null); setRowSelectionModel([]); }
          }}
          onInputChange={ (event: any, value: string, reason: any) => {if (!checkBoxChecked) {onIdDostawcaRaksInputChange(event, value, reason)}}}
          defaultValue={null}
          noOptionsText={!checkBoxChecked && 'Zacznij pisac żeby zobaczyć opcje'}
          renderInput={(params) =>
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              placeholder='Wybierz odbiorcę z Raksa'
              label={value && 'Odbiorca z Raksa'}
              autoComplete="cc-number"
              InputProps={{
                ...params.InputProps,
                startAdornment: ((appContext?.userData?.userRole !== UserRole.dostawca) &&
                  <InputAdornment sx={{order: 2, mr: 0}} position="start">
                    <Tooltip title={<div style={{textAlign: 'center'}}>Po włączeniu pokaża sie tylko Ci odbiorcy, którzy juz maja opisane towary.<br /><br />Pozostaw wyłączone jeśli chcesz wybierać spośród wszystkich odbiorców.</div>}>
                      <Checkbox icon={<StorageIcon sx={{fontSize: 33}} color="disabled" />} checkedIcon={<StorageIcon sx={{fontSize: 33}} color="primary" />} checked={checkBoxChecked} onChange={handleCheckBoxChange} />
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              />
            }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1}>
        <LoadingButton className='customAddLoadingButton'
          endIcon={<AddIcon sx={{m: '0px', fontSize: "30px !important"}}/>}
          loadingPosition="center"
          disabled={choosenOdbiorca === null}
          onClick={handleOpenModal}>
        </LoadingButton>
      </Grid>
    </Grid>
    {renderConfirmDeleteDialog()}
    <EditableGrid
      columns={detailsOfTowarForOdbiorcaColumns}
      rows={rows}
      editAction={editAction}
      loading={loadingInitialization}
      setPaginationModel={() => null}
      paginationModel={{pageSize: 100, page: 0}}
      total={0}
      setQueryOptions={() => null}
      doubleClickRowAction={() => null}
      deleteAction={removeSingleDetailsOfTowarForOdbiorcaAction}
      hideDeleteAction={false}
      hideEditAction={false}
      hideActionColumn={false}
      checkboxSelection={true}
      disableRowSelectionOnClick={true}
      setRowsSelectionModel={rowSelectionModelChange}
      rowSelectionModel={rowSelectionModel}
      hideFooter={true}
      hideFooterSelectedRowCount={true}
      columnVisibilityModel={{}}
      setColumnVisibilityModel={() => null}
      columnGroupingModel={columnGroupingModel}
      additionalStyles={undefined}
    />
    <ModalElement handleClose={handleCloseModal} open={openModal} modalContent={modalContentAddRelation()} centerCloseButton={false}
      additionalStyles={{width: '40% !important', minWidth: '500px !important', top: '10% !important'}}/>
    </> :
    <Typography sx={{ m: 2 }}>
      Nie masz uprawnień żeby zobaczyc ten widok
    </Typography>
  );
};

export default DetailsOfTowarForOdbiorcaPage;