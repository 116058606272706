import React, { useEffect } from 'react';
import { useState } from 'react';
import { Dispatch, useCallback } from 'react';
import { DecodeHintType, useZxing } from 'react-zxing';
import { BarcodeFormat } from '@zxing/library';
import { Box } from '@mui/material';

const hints = new Map();
const formats = [BarcodeFormat.CODE_128];
hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

interface BarcodeScannerModalProps {
  setBarcode: Dispatch<string>;
  closeBarcodeScannerModalAction: Dispatch<void>;
}

export function BarcodeScannerModal({ setBarcode, closeBarcodeScannerModalAction }: BarcodeScannerModalProps) {
  const [errorMessage, setErrorMessage] = useState('');
  const [torch, setTorch] = useState(false);
  const [front, setFront] = useState(false);
  const videoConstraints: MediaStreamConstraints = {
    video: {
      autoGainControl: true,
      facingMode: front ? "user" : "environment"
    }
  };

  const handleSetBarcode = useCallback((param: string) => {
    setBarcode(param)
  }, [setBarcode])
	
  const handleCloseBarcodeScannerModalAction = useCallback(() => {
    closeBarcodeScannerModalAction();
  }, [closeBarcodeScannerModalAction])

  const { ref, torch: { status: status }} = useZxing({
    constraints: videoConstraints,
    hints: hints,
    timeBetweenDecodingAttempts: 100,
    onDecodeResult(result) {
      handleSetBarcode(result.getText());
      handleCloseBarcodeScannerModalAction();
    },
    onDecodeError(error) {
    },
    onError(error) {
      if ((error as any)?.message?.includes('Requested device not found')) {
        setErrorMessage('Urządzenie nie posiada kamery.');
      }
      else if ((error as any)?.message?.includes('Permission denied')) {
        setErrorMessage('Dostęp do kamery jest zablokowany przez przegladarke.');
      } else {
        setErrorMessage(`Błąd podczas uruchamiania kamery: ${(error as any)?.message}.`);
        console.log(error);
        console.log(`${(error as any)?.code} ${(error as any)?.name} ${(error as any)?.message}`);
      }
    },
  })

  useEffect(() => {
    // navigator.mediaDevices.getUserMedia({ video: true })
    //   .then(stream => {
    //     stream.getVideoTracks().forEach(t => {t.stop(); stream.removeTrack(t);});
    //   })
    //   .catch(error => {
    //     console.log(error);
    //     console.log(`${(error as any)?.code} ${(error as any)?.name} ${(error as any)?.message}`);
    //   });
    if (status === "off") {
      setTorch(true);
      (ref.current?.srcObject as MediaStream).getVideoTracks()[0].applyConstraints({ advanced: [{ torch: true }] as unknown as MediaTrackConstraintSet[] });
    }
  }, [status]);

  return (
    <Box style={{paddingTop: '22px'}}>
      {errorMessage !== '' ? <Box>{errorMessage}</Box> : (<Box><video ref={ref} style={{maxWidth: '100%'}} /></Box>)}
      <button
        onClick={() => {
          if (torch) {
            setTorch(false);
            (ref.current?.srcObject as MediaStream).getVideoTracks()[0].applyConstraints({ advanced: [{ torch: false }] as unknown as MediaTrackConstraintSet[] });
          } else {
            setTorch(true);
            (ref.current?.srcObject as MediaStream).getVideoTracks()[0].applyConstraints({ advanced: [{ torch: true }] as unknown as MediaTrackConstraintSet[] });
          }
        }}
        hidden={errorMessage !== '' || status === "unavailable"}
        disabled={errorMessage !== '' || status === "unavailable"}
      >
        {torch ? "Wyłącz latarkę" : "Włącz latarkę"}
      </button>
      <button
        onClick={() => { setFront(!front); setErrorMessage(''); }}
        hidden={errorMessage === 'Urządzenie nie posiada kamery.' || errorMessage === 'Dostęp do kamery jest zablokowany przez przegladarke.' || errorMessage !== ''}
        disabled={errorMessage === 'Urządzenie nie posiada kamery.' || errorMessage === 'Dostęp do kamery jest zablokowany przez przegladarke.' || errorMessage !== ''}>
        {'Przełącz kamere'}
      </button>
    </Box>
  );
}